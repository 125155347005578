$(document).ready(function () {
	fullPageInit();
	menuToggle();
	foo();
	setHeiHeight();
});

function fullPageInit() {
	let aboutSection = true;
	let practicesSection = true;
	let advantagesSection = true;
	let parthnershipSection = true;
	let parthnersSection = true;
	let mapSection = true;
	let contactsSection = true;

	$("#fullpage").fullpage({
		autoScrolling: true,
		scrollHorizontally: true,
		anchors: ["1", "2", "3", "4", "5", "6", "7", "8"],
		menu: ".main-menu",
		afterLoad: function () {
			let activeSection = $("section.active").attr("class");
			switch (activeSection) {
				case "about section fp-section fp-table active":
					const aboutTitleArr = $(".about__num").toArray();
					const aboutDescriptionArr = $(
						".about__description"
					).toArray();

					if (aboutSection) {
						aboutSection = false;
						$(".about__main").css("opacity", 1);
						$(".about__main").animate_Text(20);

						aboutTitleArr.forEach((element, index) => {
							$(element).css("opacity", 1);
							$(element).animate_Text_With_Opacity(100);
						});

						aboutDescriptionArr.forEach((element, index) => {
							$(element).css("opacity", 1);
							$(element).animate_Text_With_Opacity(50);
						});
					}
					break;
				case "practices section fp-section fp-table active":
					console.log(practicesSection);
					if (practicesSection) {
						$(".practices__main-title").show();
						$(".practices__main-title").animate_Text(20);
						practicesSection = false;
					}
					break;
				case "advantages section fp-section fp-table active":
					const advantagesArr = $(
						".advantages__description"
					).toArray();
					if (advantagesSection) {
						advantagesSection = false;
						advantagesArr.forEach((element, index) => {
							$(element).show();
							$(element).animate_Text(30);

							initiateLottie(
								`advantages__item-${index + 1}`,
								`./static/app/img/0${index + 1}.json`
							);
						});

						initiateLottie("first_num", "./static/app/img/06.json");
					}
					break;
				case "partnership section fp-section fp-table active":
					const parthnershipArr = $(".partnership__text").toArray();
					if (parthnershipSection) {
						parthnershipSection = false;
						parthnershipArr.forEach((element, index) => {
							setTimeout(() => {
								$(element).css("opacity", 1);
								$(element).animate_Text(15);
							}, index * 600);
						});
					}
					break;
				case "partners section fp-section fp-table active":
					if (parthnersSection) {
						parthnersSection = false;
						const parthnersTitleArr =
							$(".partners__title").toArray();
						const parthnersDescriptionArr = $(
							".partners__description"
						).toArray();
						parthnersTitleArr.forEach((element, index) => {
							$(element).css("opacity", 1);
							$(element).animate_Text(100);
						});
						parthnersDescriptionArr.forEach((element, index) => {
							$(element).css("opacity", 1);
							$(element).animate_Text(20);
						});
					}
					break;
				case "map section fp-section fp-table active":
					if (mapSection) {
						mapSection = false;
						$(".map__caption").css("opacity", 1);
						$(".map__caption").animate_Text(30);
					}
					break;
				case "contacts section fp-section fp-table active":
					if (contactsSection) {
						contactsSection = false;
						$(".contacts__address").css("opacity", 1);
						$(".contacts__tel").css("opacity", 1);
						$(".contacts__mail").css("opacity", 1);
						$(".contacts__address").animate_Text(50);
						$(".contacts__tel").animate_Text(50);
						$(".contacts__mail").animate_Text(50);
					}
					break;
				default:
					break;
			}
		},
		afterRender: function () {
			$("#main__text").show();
			$("#main__text").animate_Text(50);
		},
		onSlideLeave: function () {
			console.log("lived");
		},
	});
}

function menuToggle() {
	let animateLettersToggle = true;
	function showMenu(isOpen) {
		var openedNav = $(".main__full"),
			mainTitle = $(".main__title"),
			sideNav = $(".main__nav");

		if (isOpen) {
			sideNav.fadeOut();
			mainTitle.fadeOut();
			openedNav.toggleClass("main__full--disable");

			$('.swiper-button-prev, .swiper-button-next').css({'opacity': 0, 'transition': '.3s'})
		} else {
			sideNav.fadeIn();
			mainTitle.fadeIn();
			openedNav.toggleClass("main__full--disable");
			$('.swiper-button-prev').css({'opacity': .2, 'transition': '.3s'})
			$('.swiper-button-next').css({'opacity': 1, 'transition': '.3s'})
		}
	}
	$(document).on("click", ".header__burger", function () {
		console.log("clicked");

		if (animateLettersToggle) {
			animateLettersToggle = false;
			let itemsArr = $(".main__page a").toArray();
			itemsArr.forEach((element, index) => {
				setTimeout(() => {
					$(element).css("opacity", 1);
					$(element).animate_Text_With_Opacity(50);
				}, index * 350);
			});
		}
	});
	$(document).on("click", ".header__toggler", function () {
		const toggle = $(this);

		if (toggle.children().eq(0).css("display") === "none") {
			toggle.children().eq(0).show();
			toggle.children().eq(1).hide();

			showMenu(false);
		} else {
			toggle.children().eq(0).hide();
			toggle.children().eq(1).show();

			showMenu(true);
		}
	});
}

function foo() {
	const swiper = new Swiper(".swiper-container", {
		direction: "horizontal",
		slidesPerView: 2,
		spaceBetween: 90,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".swiper-pagination",
			type: "custom",
			renderCustom: function (swiper, current, total) {
				console.log(total > 9 + "eto");
				return (
					`<div class="swiper-pagination-current">${
						current > 9 ? current : "0" + current
					}</div>` +
					`<div class="swiper-pagination-total">${
						total > 9 ? total : "0" + total
					}</div>`
				);
			},
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				//   spaceBetween: 40
			},
			666: {
				slidesPerView: 2,
				spaceBetween: 90,
			},
		},
	});
}

if ($(window).width() <= 666) {
	const swiperOla = new Swiper(".swiper-container-about", {
		direction: "horizontal",
		slidesPerView: 1,
		// spaceBetween: 88,
		pagination: {
			el: ".swiper-pagination",
		},
	});

	const swiperCola = new Swiper(".swiper-container-advantages", {
		direction: "horizontal",
		slidesPerView: 1,
		// spaceBetween: 90,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".swiper-pagination-advantages",
			type: "custom",
			renderCustom: function (swiper, current, total) {
				return (
					`<div class="swiper-pagination-current">${
						current > 9 ? current : "0" + current
					}</div>` +
					`<div class="swiper-pagination-total">${
						total > 9 ? total : "0" + total
					}</div>`
				);
			},
		},
	});

	const swiperPepsi = new Swiper(".swiper-container-partners", {
		direction: "horizontal",
		slidesPerView: 1,
		// spaceBetween: 90,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		pagination: {
			el: ".swiper-pagination-partners",
			type: "custom",
			renderCustom: function (swiper, current, total) {
				return (
					`<div class="swiper-pagination-current">${
						current > 9 ? current : "0" + current
					}</div>` +
					`<div class="swiper-pagination-total">${
						total > 9 ? total : "0" + total
					}</div>`
				);
			},
		},
	});
}

$.fn.animate_Text = function (timeout) {
	var string = this.html();
	string = string.replace(/\s?(<br\s?\/?>)\s?/g, "\r\n");
	return this.each(function () {
		var $this = $(this);
		$this.html(string.replace(/./g, '<span class="new">$&</span>'));
		$this.find("span.new").each(function (i, el) {
			setTimeout(function () {
				$(el).addClass("div_opacity");
			}, timeout * i);
		});
	});
};

$.fn.animate_Text_With_Opacity = function (timeout) {
	var string = this.text();
	return this.each(function () {
		var $this = $(this);
		$this.html(string.replace(/./g, '<span class="new">$&</span>'));
		$this.find("span.new").each(function (i, el) {
			setTimeout(function () {
				$(el).addClass("div_opacity-true");
			}, timeout * i);
		});
	});
};

function initiateLottie(container, path) {
	const svgContainer = document.getElementById(container);
	const animItem = bodymovin.loadAnimation({
		wrapper: svgContainer,
		animType: "svg",
		loop: false,
		autoplay: true,
		path,
	});
}


function setHeiHeight() {
    $('.footer').css({
        top: $(window).height() - $('.footer').height() + 'px'
    });
}
setHeiHeight(); // устанавливаем высоту окна при первой загрузке страницы
$(window).resize( setHeiHeight ); // обновляем при изменении размеров окна